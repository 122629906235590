import React from 'react';
import './App.css';

function App() {

    const handleStealthClick = () => {
      alert("Thanks for the curiosity, but this stealth-mode project is like a secret agent in the lead generation niche. To avoid blowing our cover, we're keeping the details under wraps for now.");
    };

  return (
    <div className="App">
      <header className="App-header">
        <img className="studio-logo" src={process.env.PUBLIC_URL + '/vendingmachine.jpg'} alt="vendingmachine.studio logo" />
        <a href="https://twitter.com/VMachineStudio" target="_blank" rel="noopener noreferrer">
    <img className="twitter-logo" src={process.env.PUBLIC_URL + '/twitter.svg'} alt="Twitter logo" />
  </a>
  <p>Even with a crazy neighbor, we're bringing new startups to life</p>
      </header>
      <main className="App-main">
        <div className="quadrant">
          <a href="https://ocean.ua" target="_blank" rel="noopener noreferrer">
            <img className="project-logo" src={process.env.PUBLIC_URL + '/ocean.jpg'} alt="ocean.ua logo" />
            <p>ocean.ua</p>
          </a>
        </div>
        <div className="quadrant">
          <a href="https://seoll-e.ai" target="_blank" rel="noopener noreferrer">
            <img className="project-logo" src={process.env.PUBLIC_URL + '/seoll-e.jpg'} alt="seoll-e.ai logo" />
            <p>seoll-e.ai</p>
          </a>
        </div>
        <div className="quadrant">
          <a href="https://finja.exchange" target="_blank" rel="noopener noreferrer">
            <img className="project-logo" src={process.env.PUBLIC_URL + '/finja.png'} alt="finja.exchange logo" />
            <p>finja.exchange</p>
          </a>
        </div>
        <div className="quadrant">
          <button onClick={handleStealthClick} style={{cursor: 'pointer', background: 'none', border: 'none', padding: 0, color: 'blue', textDecoration: 'underline'}}>
            <img className="project-logo" src={process.env.PUBLIC_URL + '/stealthmode.webp'} alt="stealth project logo" />
            <p>Project in stealth mode</p>
          </button>
        </div>
      </main>
      <footer className="App-footer">
        <p>Our machine is currently delivering more pragmatic startups...</p>
        <img className="twitter-logo" src={process.env.PUBLIC_URL + '/uaflag.svg'} alt="Ukrainian flag" />

      </footer>
    </div>
  );
}

export default App;
